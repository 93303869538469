/* You can add global styles to this file, and also import other style files */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  font-size: 16px;
  background-color: #CBDDD1;
  font-family: "Segoe UI";
}
.d-flex flex-column {
  background-color: #CBDDD1;
}
a {
  color: #6AA84F;
  text-decoration: none !important;
}


p {
  margin: 0 !important;
  padding: 0.1% !important;
  font-size: 1.03125rem !important;
  color: #63666A !important;
  line-height: 150%;
  letter-spacing: 0;
  text-align: justify;
  
}

  p + p {
    padding-top: 15px !important;
  }

.required:after {
  content: " *";
  color: red;
}

.app-modal-window .modal-dialog {
  width: 750px;
}

/* Provide sufficient contrast against white background */

.input-error {
  color: red;
}

.center {
  text-align: center;
}

code {
  color: #e01a76;
}

.navbar-nav .nav-item {
  padding: 0 .975rem;
}

  .navbar-nav .nav-item.dropdown .dropdown-toggle::after {
    vertical-align: 0.125em;
  }

.slick-slider {
/*  max-width: 100% !important;*/
  margin: auto;
}

.slick-track {
  display: flex !important;
  flex-direction: row;
/*  max-width: 100% !important;*/
}

body .slick-prev,
body .slick-next {
  height: 45px;
  width: 45px;
  z-index: 100;
}

.slick-prev:before, .slick-next:before {
  font-size: 42px;
  line-height: 1;
  color: grey;
  opacity: .87;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 95% !important;
}

  h2 .head {
  color: #1E293B;
  font-size: 30px;
}

h2.head, .left-top h2 span {
  font-family: 'Questrial',sans-serif;
}

span.sub-head.text-light {
  color: #9e7c49 !important;
  font-size: 1.125rem;
}

.toast-container {
  z-index: 2000;
}


.modal-header {
  display: flex;
  max-width: 100%;
  margin: 0.5%;
  background-color: #000000;
}

.modal-title {
  color: #ffffff;
}

.btn-close {
  margin: 1%;
  color: #ffffff;
}

.form-actions {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 1.5rem 0;
}

.button--dark-blue {
  background: #C57A0F;
  color: #fff;
  font-size: .875rem;
}

.btn:hover {
  border: 1px groove #69a84f;
}

.btn:focus {
  box-shadow: none;
}

.p-bot{
    padding-bottom: 38px;
}
